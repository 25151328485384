.u_margin-top-xs {
  margin-top: 0.8rem;
}

.u_margin-top-s {
  margin-top: 1.6rem;
}

.u_margin-top-m {
  margin-top: 2.4rem;
}

.u_margin-top-ml {
  margin-top: 4rem;
}

.u_margin-top-l {
  margin-top: 6.4rem;
}

.u_margin-top-xl {
  margin-top: 8rem;
}

.u_margin-top-xxl {
  margin-top: 12rem;
}

.u_margin-bottom-xs {
  margin-bottom: 0.8rem;
}

.u_margin-bottom-s {
  margin-bottom: 1.6rem;
}

.u_margin-bottom-m {
  margin-bottom: 2.4rem;
}

.u_margin-bottom-ml {
  margin-bottom: 4rem;
}

.u_margin-bottom-l {
  margin-bottom: 6.4rem;
}

.u_margin-bottom-xl {
  margin-bottom: 8rem;
}

.u_margin-bottom-xxl {
  margin-bottom: 12rem;
}

.u_show-on-mobile {
  display: none;

  @include respond(phone) {
    display: block;
  }
}

.u_show-on-desktop {
  display: block;

  @include respond(phone) {
    display: none;
  }
}

.u_show-on-desktop--inline {
  display: inline;

  @include respond(phone) {
    display: none;
  }
}

.u_show-on-tab {
  display: none;

  @include respond(tab-port) {
    display: block;
  }
}

.u_hide-on-tab {
  &--inline {
    display: inline;

    @include respond(tab-port) {
      display: none;
    }
  }

  @include respond(tab-port) {
    display: none;
  }
}

.u_hide-on-mobile {
  @include respond(phone) {
    display: none;
  }
}

.u_flex-center {
  align-items: center;
}

.u_flex-baseline {
  align-items: baseline;
}

.u_separator-bottom {
  padding-bottom: 12rem;
  margin-bottom: 12rem;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: calc(100% - 6.4rem);
    height: 1px;
    background-color: $color-grey-light;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @include respond(tab-port) {
      width: calc(100% - 4.8rem);
    }
  }
}

.u_visible {
  opacity: 1;
  visibility: visible;
}

.u_hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
