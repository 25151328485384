.header {
  display: flex;
  padding: 6rem 0;
  min-height: 95vh;
  min-height: calc(var(--vh, 1vh) * 95);
  align-items: center;
  pointer-events: none;

  &__container {
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  &__circle {
    position: absolute;
    //box-shadow: 0 2rem 6.4rem rgba($color-primary, 0.2);
    border-radius: 100%;
    border: 2px $color-accent-light solid;
    width: 70vw;
    height: 70vw;
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: waves 3s cubic-bezier(0, 0, 0.16, 0.99);

    @include respond(tab-port) {
      width: 100vh;
      height: 100vh;
    }

    &--first {
      animation-delay: 0.4s;
    }

    &--second {
      animation-delay: 0.7s;
    }

    &--third {
      animation-delay: 1s;
    }
  }
}
