//colors
$color-background: rgb(255, 255, 255);
$color-grey-light: #d1dded;
$color-grey-lighter: #dae4f1;
$color-primary: #2c2e47;
$color-primary-darker: #222338;
$color-accent: #557ae7;
$color-accent-attenuated: #647fd8;
$color-accent-light: #b0c8ff;
$color-accent-extralight: #d8ebfd;
$color-accent-dark: #3e21e2;

//font families
$font-default: "Poppins", sans-serif;
$font-heading: "Poppins", sans-serif;
