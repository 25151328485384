.carousel {
  width: 100%;
  position: relative;

  &__container {
    width: 100%;
    background-color: rgba($color-grey-light, 0);
    border-radius: 2rem;
    overflow: hidden;
    border: 1px solid rgba($color-primary, 0.3);
  }

  &__button {
    position: absolute;
    top: 50%;
    transition: transform 0.1s ease-out;
    background-repeat: no-repeat;
    background-position: 54% 50%;
    background-size: 25%;

    &--left {
      left: 0;
      transform: translate(-50%, -50%) scaleX(-0.75) scaleY(0.75);

      &:hover {
        transform: translate(-50%, -50%) scaleX(-0.8) scaleY(0.8);
      }

      @include respond(phone) {
        transform: translate(-30%, -50%) scaleX(-0.75) scaleY(0.75);

        &:hover {
          transform: translate(-30%, -50%) scaleX(-0.75) scaleY(0.75);
        }
      }
    }

    &--right {
      right: 0;
      transform: translate(50%, -50%) scale(0.75);

      &:hover {
        transform: translate(50%, -50%) scale(0.8);
      }

      @include respond(phone) {
        transform: translate(30%, -50%) scale(0.75);

        &:hover {
          transform: translate(30%, -50%) scale(0.75);
        }
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    padding-top: 1.6rem;
  }

  &__navigation-dot {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    background-color: rgba($color-primary, 0.3);
    transform: scale(0.6);
    transition: transform 0.1s ease-out;

    &:hover {
      cursor: pointer;
      background-color: rgba($color-primary, 0.5);
    }

    &--active {
      background-color: white;
      border: 1px rgba($color-primary, 0.8) solid;
      transform: scale(1);

      &:hover {
        cursor: unset;
        background-color: white;
      }
    }
  }

  &__slider {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    transition: transform 0.25s cubic-bezier(0.3, 0, 0.3, 1);
    line-height: 0;
    gap: 1.6rem;
  }

  &__item {
    flex-basis: 100%;
    flex-shrink: 0;
  }
}
