.card {
  @include shadow();
  padding: 6.4rem;
  border-radius: 2rem;
  flex-basis: 33.33333333%;
  display: flex;
  flex-direction: column;

  @include respond(tab-port) {
    flex-direction: row;
    align-items: center;
  }

  @include respond(phone) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__container {
    display: flex;
    gap: 3.2rem;

    @include respond(tab-port) {
      flex-direction: column;
    }
  }

  img {
    width: 6.4rem;
  }

  &__image {
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 1.6rem;
    margin-right: 3.2rem;

    @include respond(tab-port) {
      width: 10rem;
      height: 10rem;
      margin-bottom: 0;
    }

    @include respond(phone) {
      margin-bottom: 1.6rem;
    }
  }

  &__heading {
    font-size: 2rem;
    font-weight: 600;
    color: rgba($color-primary, 0.9);
    margin-bottom: 2.4rem;

    @include respond(tab-port) {
      white-space: nowrap;
      margin-right: 3.2rem;
      margin-bottom: 0;
      font-size: 2.4rem;
    }

    @include respond(phone) {
      margin-bottom: 2.4rem;
    }
  }

  .arrow {
    margin-bottom: 2.4rem;
    margin-left: 1.8rem;

    @include respond(tab-port) {
      transform: rotate(-90deg);
      margin-right: 4.8rem;
      margin-bottom: 0;
    }

    @include respond(phone) {
      transform: rotate(0);
      margin-bottom: 2.4rem;
    }
  }

  &__text {
    font-size: 1.6rem;

    @include respond(tab-port) {
      font-size: 2rem;
    }
  }
}
