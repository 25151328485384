.project {
  display: flex;
  flex-wrap: wrap;
  gap: $gutter-width;
  align-items: flex-end;
  // padding: 2rem;
  // padding-top: 2.5rem;
  // width: calc(100% + 4rem);
  // margin-left: -2rem;
  // transition: background-color 0.3s;
  // border-radius: 4rem;

  // &:hover {
  //   background-color: $color-accent-extralight;
  // }

  // &:hover &__image-box {
  //   transform: translateY(-0.5rem);
  //   box-shadow: 0rem 1rem 3rem rgba(0, 0, 0, 0.3),
  //     0rem 0.5rem 1rem rgba(0, 0, 0, 0.2);
  // }

  &--center {
    align-items: center;
  }

  @include respond(tab-port) {
    gap: $gutter-width-small;
    // padding: 0rem;
    // width: 100%;
    // margin-left: 0;
    // border-radius: initial;
  }

  &:not(&:last-child) {
    border-bottom: 1px solid rgba($color-primary, 0.2);
    padding-bottom: 6rem;
    margin-bottom: 6rem;
  }

  &__image-box {
    display: block;
    position: relative;
    width: 100%;
    height: 44rem;
    background: $color-grey-lighter;

    border-radius: 2.4rem;
    overflow: hidden;
    //box-shadow: 0rem 0rem 3rem rgba(0, 0, 0, 0.2),
    //0rem 0.5rem 1rem rgba(0, 0, 0, 0.2);

    cursor: pointer;
    transition: all 0.3s ease-out;

    @include respond(phone) {
      height: 30rem;
    }
  }

  &__image-box:hover &__img {
    transform: scale(1.08);
    transition: transform 0.3s ease-in-out;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.35s ease-in-out;
    pointer-events: none;
  }

  &__name,
  &__description,
  &__tag-list {
    pointer-events: none;
  }

  &__name {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.2rem;
    color: $color-accent;
    text-transform: uppercase;

    &--small {
      font-size: 1.6rem;
      letter-spacing: 0.15rem;

      @include respond(phone) {
        font-size: 1.8rem;
        letter-spacing: 0.18rem;
      }
    }

    @include respond(phone) {
      margin-bottom: 3.5rem;
      padding: 0 0.5rem;
    }
  }

  &__description {
    max-width: 55rem;
    font-size: 3.2rem;
    font-family: $font-heading;
    font-weight: 300;
    margin-top: 3.8rem;
    line-height: 130%;
    color: rgba($color-primary, 0.95);
    strong {
      display: inline-block;
      font-weight: 300;
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0.24rem;
        height: 4px;
        background-color: rgba($color-accent-light, 0.6);
        z-index: -1;
      }
    }

    @include respond(phone) {
      font-size: 3.6rem;
      line-height: 4.8rem;
      padding: 0 0.5rem;
      margin-top: -0.5rem;
    }
  }

  &__tag-list {
    padding-bottom: 1rem;
    margin-top: 5.2rem;

    @include respond(phone) {
      padding: 0 0.5rem;
      margin-top: 3.7rem;
    }
  }

  &__tag {
    font-size: 1.4rem;
    border-radius: 0.5rem;
    border: 1px solid rgba($color-primary, 0.5);
    padding: 0.5rem 1rem;
    display: inline-block;
    color: rgba($color-primary, 0.8);

    &:not(&:last-child) {
      margin-right: 1rem;
    }

    @include respond(phone) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }
}

.exploreBubble {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: center;
  z-index: 100;

  &__background {
    position: absolute;
    width: 10rem;
    height: 10rem;
    background-color: $color-accent;
    border-radius: 100%;
    //box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: white;
    font-weight: 500;
    letter-spacing: 0.1rem;
    width: 10rem;
    height: 10rem;
    opacity: 0.85;

    transition: all 0.3s ease-in-out;

    span {
      z-index: 2;
    }

    &--show {
      //opacity: 0.85;
      transform: scale(1);
    }

    &--hide {
      //opacity: 0;
      transition-duration: 0.2s;
      transform: scale(0);
    }
  }
}
