.section-projects {
  padding: 12rem 0;
  position: relative;

  .details__support-content {
    padding: 0;

    &::before {
      display: none;
    }
  }

  .details__video {
    border-radius: 0;
  }
}

.youtube-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.youtube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.stanwars-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 62%;
  height: 0;
  background-color: #1d1c1d;
  border-radius: 2.4rem;
  overflow: hidden;

  .details__video {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
  }
}
