.insight {
  pointer-events: none;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 60rem;

    @include respond(tab-port) {
      flex-direction: column;
    }
  }

  &__main-container {
    flex-basis: 0;
    flex-grow: 6;
    flex-shrink: 0;
  }

  &__main-content {
    height: 100%;
    padding: 8rem 12rem;

    @include respond(tab-port) {
      padding: 6.4rem 6.4rem;
    }
  }

  &__heading {
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 150%;
    letter-spacing: 0.16rem;
    margin-bottom: 4.8rem;
    position: relative;
    padding-left: 1.6rem;

    &::before {
      content: "";
      display: block;
      width: 3px;
      background-color: $color-accent-attenuated;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__main-text {
    font-size: 2.8rem;
    line-height: 150%;
    margin-bottom: 4.8rem;
    font-weight: 300;

    strong {
      font-weight: 500;
      color: rgba($color-primary, 0.9);
    }

    @include respond(phone) {
      font-size: 2.4rem;
    }
  }

  &__body-text {
    font-size: 1.8rem;
    line-height: 160%;
  }

  &__secondary-container {
    flex-basis: 0;
    flex-grow: 4;
    flex-shrink: 0;

    @include respond(tab-port) {
      flex-grow: 0;
    }
  }

  &__secondary-content {
    display: flex;
    align-items: flex-end;
    height: 100%;
    min-height: 20rem;
    padding: 2.4rem;
    flex-wrap: wrap;
    background-color: grey;
    background-size: cover;
    background-position: center;
  }

  &__quote-container {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 1.6rem;
    padding: 3.2rem 4.8rem;
    flex-basis: 100%;
    flex-shrink: 1;
  }

  &__quote {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2.4rem;
    line-height: 130%;
    font-style: italic;

    @include respond(phone) {
      font-size: 1.8rem;
    }
  }

  &__quote-author {
    font-size: 1.6rem;
  }
}

.benchmark {
  &__main-text {
    font-size: 2.2rem;
    line-height: 140%;
    font-weight: 300;
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 4.8rem;
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__heading-icon {
    width: 2.8rem;
    height: 2.8rem;
    background-size: cover;
  }

  &__list {
    line-height: 130%;
    font-size: 1.8rem;
    font-weight: 300;
  }

  &__list-item {
    display: flex;
    padding-left: 1.2rem;
    &::before {
      content: "•";
      margin-right: 0.8rem;
    }

    &:not(&:first-child) {
      margin-top: 0.8rem;
    }
  }
}
