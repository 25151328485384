.navbar {
  display: flex;
  position: fixed;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 6.4rem;
  background-color: rgba(white, 0);
  box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0);
  transition: 0.2s;

  z-index: 999;

  &--scrolled {
    background-color: white;
    box-shadow: 0rem 0.2rem 1.6rem rgba(0, 0, 0, 0.1);
  }

  @include respond(tab-land) {
    padding: 1.6rem 3.2rem;
  }

  @include respond(phone) {
    padding: 1.6rem 2rem;
  }

  &__logo-box {
    width: 4rem;
    height: 4rem;
    background-color: $color-accent;

    @include respond(phone) {
      width: 6rem;
      height: 6rem;
    }
  }

  &__list-item {
    display: inline-block;
    position: relative;
    &:not(&:last-child) {
      margin-right: 4rem;
    }

    &::after {
      display: block;
      content: "";
      height: 2px;
      width: 16px;
      background-color: $color-accent;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) scaleX(0);
      bottom: -0.4rem;
      transition: transform 0.15s;
    }

    &:hover {
      &::after {
        display: block;
        content: "";
        height: 2px;
        width: 16px;
        background-color: $color-accent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scaleX(1);
        bottom: -0.4rem;
      }
    }

    &--active,
    &--active:hover {
      &::after {
        display: block;
        content: "";
        height: 2px;
        width: unset;
        background-color: $color-accent;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.4rem;
        transform: translateX(0) scaleX(1);
        transition: none;
      }
    }
  }

  &__link {
    font-size: 1.8rem;
    font-weight: 500;

    &:link,
    &:visited {
      color: black;
    }
  }
}
