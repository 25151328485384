// MEDIA QUERY MANAGER

/*
0-600px     phone
600-900px   tablet portrait
900-1200px  normal styles apply
1800px +    big desktops

 $breakpoint argument options
 - phone
 - tab-port
 - tab-land
 - big-desktop
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone-small {
    @media (max-width: 20em) {
      @content;
    } //320px
  }
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == small-desktop {
    @media (max-width: 112.5em) {
      @content;
    } //1800px
  }
}

@mixin column($span) {
  flex: 1 1 auto;
  flex-basis: calc(
    #{$span} * (100% - 11 * #{$gutter-width}) / 12 + (#{$span} - 1) * #{$gutter-width}
  );
}

@mixin shadow() {
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.08),
    0 0.3rem 0.6rem rgba(0, 0, 0, 0.1);
}
