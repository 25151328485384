.button {
  display: inline-block;
  padding: 1.2em 2.4em;
  border-radius: 100rem;
  font-weight: 500;
  cursor: pointer;
  //box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.2);

  &--link {
    display: inline-flex;
    gap: 1.6rem;
    justify-content: center;
    font-weight: 300;
    letter-spacing: 0.05rem;
    .arrow {
      transition: transform 0.3s ease-out;
    }

    &:hover {
      .arrow {
        transform: translateX(0.4rem) rotate(-90deg);
        transition: transform 0.15s ease-out;
      }
    }
  }

  &--primary {
    background: $color-primary;
    color: $color-background;
    transition: background-color 0.3s ease-out;
    font-size: 1.6rem;

    @include respond(tab-land) {
      font-size: 1.8rem;
    }

    &:hover {
      background-color: $color-primary-darker;
      transition: background-color 0.15s ease-out;
    }
  }

  &--secondary {
    border: 1px solid rgba($color-primary, 0.4);
    display: inline-flex;
    font-size: 1.4rem;
    font-weight: 500;
    align-items: center;
    padding: 1.6rem 3.2rem;
    color: $color-primary;

    @include respond(tab-land) {
      font-size: 1.8rem;
    }

    &:hover {
      border-color: rgba($color-primary, 0.6);
    }
  }

  &-round {
    border-radius: 100%;
    background-color: white;
    border: none;
    box-shadow: 0 0.2rem 0.8rem rgba($color-primary, 0.3);

    &:hover {
      box-shadow: 0 0.4rem 1.6rem rgba($color-primary, 0.2);
    }

    &--big {
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  &--disabled {
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 100%;
      background-color: white;
      opacity: 0.7;
    }
  }
}

.toggle-button {
  padding: 6px;
  background-color: rgba($color-primary, 0.15);
  border-radius: 100px;
  pointer-events: none;
  transition: background-color 0.1s ease-in-out;
  &:hover {
    background-color: rgba($color-primary, 0.22);
  }

  &__container {
    display: flex;
    gap: 2px;
    border-radius: 100px;
    position: relative;
  }

  &__option {
    font-size: 1.4rem;
    padding: 1.2rem 3.2rem;
    background-color: transparent;
    border: none;
    border-radius: 100px;
    position: relative;

    &--selected {
      font-weight: 600;
      letter-spacing: 0.046rem;
    }

    &--unselected {
      font-weight: 500;
      letter-spacing: 0.06rem;
      pointer-events: auto;
      cursor: pointer;
    }
  }

  &__toggle {
    font-size: 1.4rem;

    letter-spacing: 0.046rem;
    position: absolute;

    font-weight: 600;
    top: 0;

    color: white;
    transition: 0.15s ease-out;

    &--first {
      left: 0;
      right: unset;
    }

    &--second {
      left: 100%;
      transform: translateX(-100%);
    }
  }

  &__toggle-box {
    border-radius: 100px;
    padding: 1.2rem 3.2rem;
    background-color: white;
    box-shadow: 0 1px 3px rgba($color-primary, 0.2);
    &--moving {
      //animation: stretch 0.15s ease-in-out;
    }
  }
}
