.details {
  padding-bottom: 12rem;

  &__section-intro {
    display: flex;
    flex-wrap: wrap;
    gap: 3.2rem;
    min-height: 90vh;
    padding: 12rem 0;
    align-items: center;
    justify-content: center;

    .project__tag-list {
      margin-top: 6rem;

      opacity: 0;
      animation: moveInRight 1s cubic-bezier(0.2, 0, 0.03, 1) forwards;
    }
  }

  &__intro-text {
    @include column(6);

    .heading-secondary {
      opacity: 0;
      animation: moveInRight 1.3s cubic-bezier(0.2, 0, 0.03, 1) 0.05s forwards;
    }

    .project__name {
      opacity: 0;
      animation: moveInRight 1s cubic-bezier(0.2, 0, 0.03, 1) forwards;
    }

    @include respond(tab-port) {
      flex-basis: 100%;
    }
  }

  &__intro-summary {
    @include column(5);
    padding: 8rem;
    border-radius: 3.2rem;
    //box-shadow: 0 0.6rem 1.6rem rgba($color-primary, 0.15);
    @include shadow();
    //border: 1px rgba($color-primary, 0.3) solid;
    opacity: 0;
    animation: moveInRight 1s cubic-bezier(0.2, 0, 0.03, 1) 0.2s forwards;
    //animation-delay: 0.2s;

    @include respond(phone) {
      padding: 8rem 4.8rem;
    }

    &__content {
      font-size: 1.6rem;

      @include respond(phone) {
        font-size: 1.8rem;
      }
    }

    &__item {
      &:not(&:last-child) {
        margin-bottom: 3.2rem;
      }
    }

    &__label {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $color-accent;
      margin-bottom: 0.2rem;

      @include respond(phone) {
        font-size: 1.4rem;
      }
    }

    @include respond(tab-port) {
      flex-basis: 100%;
    }
  }

  &__hero {
    height: 40vw;
    background: linear-gradient(
      to right bottom,
      rgba($color-grey-light, 0.5),
      rgba($color-grey-light, 1)
    );
    overflow: hidden;
    position: relative;

    @include respond(tab-port) {
      height: 80vh;
    }

    &__container {
      transform: scale(1.2) translateY(20rem) rotate(-10deg);
    }

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include respond(tab-port) {
        left: 65%;
        width: 350%;
      }
    }
  }

  &__overview {
    padding-top: 12.8rem;
  }

  .heading-tertiary {
    @include column(12);
    flex-grow: 0;
  }

  &__paragraph {
    @include column(8);
    flex-grow: 0;
    @include respond(tab-port) {
      flex-basis: 100%;
    }
  }

  &__content {
    &:nth-of-type(odd) {
      .details__support-content {
        order: -1;
      }
    }
  }

  &__main-content {
    @include column(8);
    border-radius: 2.4rem;
    //box-shadow: 0 1rem 2rem rgba($color-primary, 0.2);

    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    align-items: center;
    justify-content: center;

    &--image {
      overflow: hidden;
    }

    @include respond(tab-port) {
      flex-basis: 100%;
      width: 100%;
      order: 2;
    }

    img {
      width: 100%;
      // background: linear-gradient(
      //   to bottom,
      //   rgba($color-grey-light, 0.7),
      //   rgba($color-grey-light, 1)
      // );
    }

    &--full-width {
      @include column(12);
    }
  }

  &__support-content {
    @include column(4);
    padding-left: 1.6rem;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0.4rem;
      bottom: 0.4rem;
      width: 2px;
      background-color: $color-accent-light;
    }

    &--above {
      justify-self: flex-start;
      @include column(6);
      flex-grow: 0;
      order: -1;

      @include respond(tab-port) {
        flex-grow: 1;
      }
    }
  }

  &__overview-highlight {
    &:nth-child(2) {
      .details__main-content {
        order: 2;
      }
    }

    &:not(&:last-child) {
      margin-bottom: 8rem;
    }

    @include respond(tab-port) {
      gap: 4rem;
    }
  }

  &__section {
    max-width: 128rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__summary-container {
    display: flex;
    align-items: stretch;
    background-color: white;
    border-radius: 2.4rem;
    padding: 6.4rem 2.4rem;
    width: 100%;

    @include shadow;

    @include respond(tab-port) {
      flex-direction: column;
      padding: 3.2rem 4.8rem;
    }
  }

  &__summary-box {
    flex: 1 0 0;
    padding: 2.4rem 4.8rem;
    //border-radius: 2.4rem;
    //padding: 3.2rem;
    //box-shadow: 0 0.6rem 1.6rem rgba($color-primary, 0.15);
    //@include shadow();

    &:not(&:last-child) {
      border-right: 1px solid $color-grey-light;

      @include respond(tab-port) {
        border-bottom: 1px solid $color-grey-light;
        border-right: none;
      }
    }

    @include respond(tab-port) {
      flex-basis: 100%;
      padding: 6.4rem 0rem;
    }
  }

  &__summary-title-box {
    line-height: 2.4rem;
    font-size: 1.6rem;

    @include respond(tab-port) {
      font-size: 2rem;
    }

    @include respond(phone) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }

  &__summary-number {
    font-weight: 500;
    color: $color-accent;
  }

  &__summary-title {
    text-transform: uppercase;
    letter-spacing: 0.075rem;
    font-weight: 500;
  }

  &__summary-content-list {
    padding-top: 1.2rem;
  }

  &__summary-content-item {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 160%;
    padding-left: 2.4rem;
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      top: 0rem;
      content: "\2192";
      color: $color-accent;
    }

    &:not(&:last-child) {
      margin-bottom: 0.8rem;
    }

    @include respond(tab-port) {
      font-size: 2rem;
      padding-left: 3.2rem;
    }

    @include respond(phone) {
      font-size: 1.8rem;
    }
  }

  &__chapter-heading {
    position: relative;
    .waves {
      position: absolute;
      width: 300rem;
      height: 300rem;
      box-shadow: 0 1rem 4rem rgba($color-accent, 0.3);
      left: 18.4rem;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 250rem;
        height: 250rem;
        box-shadow: 0 1rem 4rem rgba($color-accent, 0.3);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 200rem;
        height: 200rem;
        box-shadow: 0 1rem 4rem rgba($color-accent, 0.3);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 3.2rem;
    }

    background-color: $color-accent-light;
    padding: 6.4rem 0;
    margin: 12rem 0;
    margin-top: 16rem;
    overflow: hidden;
  }

  &__video-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 4.8rem;
    position: relative;
  }

  &__video {
    border-radius: 2.4rem;
  }

  &__link-container {
    width: 100%;
    text-align: center;
    padding: 8rem 0;
  }

  &__link {
    font-size: 2rem;
    color: $color-primary;
    padding: 0.8rem 0;
    position: relative;
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    gap: 1.2rem;

    &::after {
      position: absolute;
      display: block;
      content: "";
      bottom: 0;
      height: 1px;
      background-color: $color-accent-attenuated;
      left: 0;
      right: 0;
      border-radius: 100rem;
      transform: scaleX(0.5);
      transition: transform 0.2s;
      transform-origin: 0% 50%;

      @include respond(tab-port) {
        transform: scaleX(1);
      }
    }

    &:hover {
      cursor: pointer;
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

.fact {
  width: 100%;
  @include shadow();
  border-radius: 2rem;
  padding: 6.4rem 8rem;
  padding-bottom: 10rem;

  @include respond(phone) {
    padding: 4rem 4rem;
    padding-bottom: 8rem;
  }

  &__heading {
    margin-bottom: 2.4rem;
    margin-top: 4.8rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1rem;
    font-size: 1.8rem;
    @include respond(tab-land) {
      font-size: 2rem;
    }
  }

  &__heading-number {
    font-size: 9.6rem;
    color: $color-accent-attenuated;
    font-weight: 500;

    @include respond(phone) {
      font-size: 8rem;
    }
  }

  &__heading-text {
    font-size: 3.6rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    @include respond(phone) {
      font-size: 3.2rem;
    }
  }

  &__list {
  }

  &__list-item {
    display: flex;
    gap: 2.4rem;
    line-height: 160%;
    letter-spacing: 0.02rem;
    &:not(&:last-child) {
      margin-bottom: 2.4rem;
    }

    strong {
      font-weight: 500;
      color: rgba($color-primary, 0.92);
    }

    font-size: 2rem;
    @include respond(tab-land) {
      font-size: 2.2rem;
    }
  }
}

.figma-prototype {
  width: 100%;
  border: 1px $color-grey-light solid;
  border-radius: 2.4rem;
  height: 80rem;
  background-color: white;
}
