@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveOutBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes arise {
  0% {
    box-shadow: 0 0 0 rgba($color-primary, 0.08);
    transform: scale(0.99);
  }
  100% {
    box-shadow: 0 0.8rem 3.6rem rgba($color-primary, 0.08);
    transform: scale(1);
  }
}

@keyframes stretch {
  0% {
    transform: (scaleX(1));
  }
  50% {
    transform: (scaleX(1.5));
  }
  100% {
    transform: (scaleX(1));
  }
}

@keyframes waves {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.75);
    opacity: 0;
  }
}
