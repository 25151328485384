//variables
$gutter-width: 6.4rem;
$gutter-width-small: 4.8rem;

.container {
  max-width: 128rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.2rem;
  padding-right: 3.2rem;

  @include respond(phone) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  &--flex {
    display: flex;
    flex-wrap: wrap;
    gap: $gutter-width;

    &.u_flex-baseline {
      gap: $gutter-width-small;
    }
  }
}

.flex-column {
  flex: 1 0 40rem;

  @include respond(phone-small) {
    flex: 1 0 30rem;
  }
}

.single-column {
  @include column(1);
}

.footer {
  font-size: 1.6rem;
  padding: 12rem 0;
  //text-align: center;
  //border-top: 1px solid $color-grey-light;
  display: flex;
  align-items: center;
  background-color: rgba($color-grey-lighter, 0.5);

  &__icon {
    width: 3.2rem;
    height: 3.2rem;
    display: inline-block;
    margin-right: 0.8rem;

    &--phone {
      margin-right: 0.4rem;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6.4rem;
    width: 100%;
    justify-content: space-between;
  }

  &__contacts-container {
    display: flex;
    align-items: center;
    gap: 3.2rem;
    flex-wrap: wrap;
  }

  &__contact {
    display: flex;
    align-items: center;
  }

  &__separator {
    width: 1px;
    height: 3.2rem;
    background-color: rgba($color-primary, 0.15);
    @include respond(phone) {
      background-color: transparent;
    }
  }

  &__copyright {
    margin-left: 0.4rem;
    color: rgba($color-primary, 0.6);
  }
}
