.container-introduction {
  @include respond(phone) {
    //padding: 0 0;
  }
}
.introduction {
  display: flex;
  justify-content: flex-end;
  //@include respond(tab-port) {
  //justify-content: flex-start;
  //}
  padding: 12rem 12rem;
  border-radius: 2.4rem;
  background: linear-gradient(
    to bottom right,
    rgba($color-accent-extralight, 1),
    rgba($color-accent-light, 1)
  );

  @include respond(tab-port) {
    padding: 12rem 6rem;
  }

  @include respond(phone) {
    padding: 9.2rem 3.2rem;
    //border-radius: 0;
  }

  &__text {
    flex: 0 0 calc((100% - #{$gutter-width}) / 2);
    font-size: 2rem;
    line-height: 160%;
    font-weight: 300;

    @include respond(tab-port) {
      flex: 0 0 calc(2 * (100% - 2 * #{$gutter-width}) / 3 + #{$gutter-width});
    }

    @include respond(phone) {
      flex: 1;
    }
  }

  &__paragraph {
    color: rgb(10, 15, 20);

    strong {
      font-weight: 500;
      color: $color-primary;
    }

    &:first-child {
      margin-bottom: 2rem;
    }
  }
}
