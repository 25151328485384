html {
  font-family: $font-default;
}

.heading-primary {
  &__main {
    display: block;
    font-size: 10rem;
    line-height: 105%;
    font-family: $font-heading;
    font-weight: 800;
    color: rgba($color-primary, 1);

    @include respond(tab-port) {
      font-size: 14rem;
      line-height: 14.2rem;
    }

    @include respond(phone) {
      font-size: 7.8rem;
      line-height: 9.2rem;
    }

    @include respond(phone-small) {
      font-size: 6rem;
      line-height: 6.2rem;
    }

    &--top {
      display: block;
      opacity: 0;
      animation: moveInLeft 1.5s cubic-bezier(0.5, 0, 0, 1) 0.1s forwards;
    }

    &--center {
      opacity: 0;
      animation: fadeIn 0.8s ease-in-out 0.2s forwards;
    }

    &--bottom {
      display: block;
      opacity: 0;
      animation: moveInRight 1.5s cubic-bezier(0.5, 0, 0, 1) 0.1s forwards;
    }
  }

  &__sub {
    display: block;
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 3rem;
    letter-spacing: 0.2rem;
    font-weight: 500;
    color: $color-accent;
    opacity: 0;
    animation: moveInTop 1.2s cubic-bezier(0.21, 0, 0, 0.99) 0.4s forwards;
  }
}

.heading-secondary {
  display: inline-block;
  position: relative;
  font-size: 6.4rem;
  font-family: $font-heading;
  font-weight: 600;
  line-height: 115%;
  color: rgba($color-primary, 1);

  &--bold {
    font-weight: 600;
    font-size: 10rem;
  }

  &--arrow {
    &::after {
      font-family: $font-default;
      //content: "\2193";
      font-size: 2.8rem;
      margin-left: 1rem;
      display: inline-block;
      transform: translateY(-0.8rem);
    }
  }

  &--chapter {
  }

  @include respond(phone) {
    font-size: 5rem;
  }

  .highlight {
    display: inline-block;
    color: $color-accent-attenuated;
    font-weight: 600;
    //font-style: italic;
  }

  &--number {
    font-size: 20rem;
    color: white;
    font-weight: 500;
    margin-left: -1.6rem;

    @include respond(phone) {
      font-size: 12rem;
      margin-left: -0.6rem;
    }
  }
}

.avoid-wrap {
  display: inline-block;
}

.heading-tertiary {
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 120%;
  letter-spacing: 0.3rem;
  font-weight: 400;
  // padding-left: 1.6rem;
  position: relative;
  color: $color-accent;
  @include respond(tab-land) {
    font-size: 2.4rem;
    letter-spacing: 0.18rem;
  }

  // &::before {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   top: 0.3rem;
  //   bottom: 0.3rem;
  //   width: 3px;
  //   background-color: $color-accent-attenuated;
  // }
}

.heading-four {
  font-size: 3.8rem;
  //font-family: $font-heading;
  font-weight: 400;
  line-height: 130%;
  color: rgba($color-primary, 0.95);

  .highlight {
    font-weight: 700;
  }

  @include respond(tab-land) {
    font-size: 4rem;
  }
}

.heading-five {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.07rem;
  text-transform: uppercase;

  @include respond(tab-land) {
    font-size: 1.8rem;
  }
}

.body-text {
  font-size: 2rem;
  margin-top: 1.6rem;

  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.02em;

  strong {
    font-weight: 500;
  }

  @include respond(tab-land) {
    font-size: 2.2rem;
  }

  &--small {
    font-size: 1.5rem;
    margin-top: 1rem;
    @include respond(tab-land) {
      font-size: 2rem;
    }
  }

  &--big {
    font-size: 2.2rem;
  }
}

.arrow {
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
  flex-shrink: 0;

  &--inline {
    display: inline-block;
  }

  &::before {
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    border-right: 2px solid $color-accent-attenuated;
  }

  &::after {
    display: block;
    position: absolute;
    content: "";
    border-left: 2px solid $color-accent-attenuated;
    border-top: 2px solid $color-accent-attenuated;
    width: 30%;
    height: 30%;
    transform: translateX(-60%) rotate(-135deg);
    bottom: 3%;
    left: 50%;
  }

  &--horizontal {
    transform: rotate(-90deg);
    &::after,
    &::before {
      border-width: 1px;
    }

    &::after {
      transform: translateX(-55%) rotate(-135deg);
    }

    &--flipped {
      transform: rotate(90deg);
    }
  }

  &--light {
    &::before,
    &::after {
      border-color: $color-accent-light;
    }
  }
}
